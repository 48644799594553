var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rate-manage-container"},[_c('div',{ref:"tableContainer",staticClass:"container-content"},[_c('a-table',{attrs:{"locale":{ emptyText: this.tableLoading ? '加载中...' : '暂无数据' },"scroll":{ y: _vm.tableHeight },"columns":[
				{
					title: '序号',
					dataIndex: 'key',
					align: 'center',
					width: 90
				},
				{
					title: '预警类型',
					dataIndex: 'name',
					align: 'center',
					ellipsis: true
				}
			],"data-source":_vm.tableData,"pagination":false}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }